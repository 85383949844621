import React, { useState } from 'react'
import Script from 'react-load-script'
import './App.css'
import { Button, Form, Input, Modal } from 'semantic-ui-react'

const GOOGLE_MAPS_API_KEY = 'AIzaSyC_e9lP4ynkucD2YPYBR2-cmL0oMbWXmQ4'

const AddressForm = ({ subKey, address, clearAddress, updateAddress, formDispatch, setAddressForm }) => {

  const [showModal, setShowModal] = useState(true)
  const { name, addr1, city, state, postalCode } = address

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace()
    let address = parseAddressComponents(addressObject)
    formDispatch({
      type: 'updateAddress', payload: {
        subKey,
        address: {
          name: address.name,
          addr1: address.street,
          city: address.locality,
          state: address.administrative_area_level_1,
          postalCode: address.zipcode
        }
      }
    })
    document.getElementById('autocomplete').value = ''
  }

  const parseAddressComponents = ({ name, address_components }) => {
    const addressBits = { name: '' }
    let type
    address_components.map(c => {
      type = c.types[0]
      switch (type) {
        case 'administrative_area_level_1':
          addressBits[type] = c.short_name
          return null
        case 'route':
          addressBits[type] = c.long_name
          addressBits.route_short = c.short_name
          return null
        default:
          addressBits[type] = c.long_name
      }
      return null
    })
    if (addressBits.postal_code_suffix) {
      addressBits.zipcode = `${addressBits.postal_code}-${addressBits.postal_code_suffix}`
    } else {
      addressBits.zipcode = addressBits.postal_code
    }
    if (addressBits.street_number) {
      addressBits.street = `${addressBits.street_number} ${addressBits.route}`
      addressBits.street_short = `${addressBits.street_number} ${addressBits.route_short}`
    } else {
      addressBits.street = addressBits.route
      addressBits.street_short = addressBits.route_short

    }
    if (name !== addressBits.street_short && name !== addressBits.street) {
      addressBits.name = name
    }
    return addressBits
  }

  let autocomplete = null
  const handleGoogleApiLoaded = () => {

    // Declare Options For Autocomplete 
    //const options = { types: ['(cities)'] }
    //const options = {
    //  fields: ['address_component', 'formatted_address']
    //}
    const options = {}
    const autocompleteEl = document.getElementById('autocomplete')
    // Initialize Google Autocomplete 
    /*global google*/
    autocomplete = new google.maps.places.Autocomplete(
      autocompleteEl,
      options
    )
    // Fire Event when a suggested name is selected
    autocomplete.addListener('place_changed', handlePlaceSelect)
    autocompleteEl.focus()
  }

  const enterKeyHandler = () => {
    if (document.getElementById('autocomplete') === document.activeElement) { return }
    setAddressForm(null)
  }

  const closeModal = () => {
    setAddressForm(null)
    setShowModal(false)
  }

  return (

    <Modal open={showModal} onClose={closeModal} closeIcon={true} centered={false}>

      <Modal.Content>

        <div className="App">

          <Script url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`} onLoad={handleGoogleApiLoaded} />
          <Form onSubmit={event => event.preventDefault()}>

            <h2 className={subKey}>{subKey[0].toUpperCase() + subKey.slice(1)}
              <button onClick={enterKeyHandler} style={{ display: 'none' }} />
              <Button onClick={clearAddress} type="button" className="clear"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19"><path d="M5.9 3.2V.5h7.2v2.7h5.4V5h-1.906L15.8 18.5H3.2L2.406 5H.5V3.2h5.4zm1.8 0h3.6v-.9H7.7v.9zM4.21 5l.688 11.7h9.206L14.79 5H4.21z" fill="#414550" fillRule="nonzero" /></svg></Button>
            </h2>

            <Form.Field>
              <Input id="autocomplete" icon='search' placeholder='Search address...' />
            </Form.Field>

            <Form.Field id="name" placeholder='Name' control='input' value={name} onChange={updateAddress} />

            <Form.Field id="addr1" placeholder='Street' control='input' value={addr1} onChange={updateAddress} />

            <Form.Group>
              <Form.Field width={7} id="city" placeholder='City' control='input' value={city} onChange={updateAddress} />
              <Form.Field width={3} id="state" placeholder='State' control='input' value={state} onChange={updateAddress} />
              <Form.Field width={6} id="postalCode" placeholder='Zip' control='input' value={postalCode} onChange={updateAddress} />
            </Form.Group>

            <Button primary className="save" onClick={closeModal}>Save</Button>

          </Form>

        </div>

      </Modal.Content>

    </Modal>


  )
}

export default AddressForm