import React, { Fragment } from 'react'
import { inchToPx, makeAllCaps } from './Pdf'

const addressLine = (address) => {
  const lineText = []
  if (address.city) {
    lineText.push(address.city)
  }
  if (address.state) {
    lineText.push(` ${address.state}`)
  }
  if (address.postalCode) {
    lineText.push(`${'\u00A0'} ${address.postalCode}`)
  }
  return lineText.join('')
}

const addressEmpty = address => {
  for (const key of Object.keys(address)) {
    if (address[key] !== '') { return false }
  }
  return true
}

const Preview = ({ data, openAddressEditor }) => {

  data = makeAllCaps(data)

  const { size, sender, recipient } = data
  //const height = inchToPx(size.height)
  const width = inchToPx(size.width)
  const ratio = Math.floor((size.height / size.width) * 100)

  const senderEmpty = addressEmpty(sender)
  const recipientEmpty = addressEmpty(recipient)

  return (

    <div>
      <div className="envelope-sample">
        <div className="aspect-ratio" style={{ width: `${width}px`, paddingBottom: `${ratio}%` }}>

          <div onClick={() => openAddressEditor('sender')} className="sender-block" style={{ fontSize: `calc(9px + (${width} / 61)` }}>

            {senderEmpty ? (
              <div className="dim">click to add sender</div>
            ) : (
                <Fragment>
                  <div className="sender-name">{sender.name}</div>
                  {sender.addr1 && <div>{sender.addr1}</div>}
                  <div>{addressLine(sender)}</div>
                </Fragment>
              )
            }

          </div>

          <div onClick={() => openAddressEditor('recipient')} className="recipient-block" style={{ fontSize: `calc(12px + (${width} / 61)` }}>

            {recipientEmpty ? (
              <div className="dim">click to add recipient</div>
            ) : (
                <Fragment>
                  <div className="recipient-name">{recipient.name}</div>
                  {recipient.addr1 && <div>{recipient.addr1}</div>}
                  <div>{addressLine(recipient)}</div>
                </Fragment>
              )
            }

          </div>

        </div>
      </div>
    </div>

  )
}

export default Preview