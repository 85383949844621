import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

// PDF-LIB
// https://pdf-lib.js.org/docs/api/interfaces/pdfpagedrawtextoptions

// Mozilla's PDF Viewer Lib -- just using built-in PDF viewer for now.
// https://github.com/mozilla/pdf.js/wiki/Setup-pdf.js-in-a-website


// Envelope address design rules
// https://support.smartpress.com/direct-mail/mailing-design-guidelines#envelope_mailing

// USPS Guidelines!
// https://pe.usps.com/BusinessMail101?ViewName=DeliveryAddress
// https://pe.usps.com/BusinessMail101?ViewName=Letters

/* Envelope sizes in pixels
#10   395 x 912
#6.5  348 x 624

const envelopeTypes = {
  '#10': {
    height: 4.25,
    width: 9.5,
  },
}
*/

const inchToPx = inches => inches * 72

const makeAllCaps = data => {
  ['sender', 'recipient'].map(addr => {
    for (const key of Object.keys(data[addr])) {
      if (data[addr][key]) {
        data[addr][key] = data[addr][key].toUpperCase()
      }
    }
    return null
  })
  return data
}

// https://stackoverflow.com/questions/35038884/download-file-from-bytes-in-javascript
function saveByteArray(fileName, byte) {
  var blob = new Blob([byte], { type: "application/pdf" });
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const Pdf = async (data, setPdfUint8Array) => {

  data = makeAllCaps(data)

  const { size, sender, recipient } = data
  const height = inchToPx(size.height)
  const width = inchToPx(size.width)


  const pdfDoc = await PDFDocument.create()
  // metadata
  pdfDoc.setTitle('envelope.pdf')
  pdfDoc.setAuthor('Envelope by Sawmill Design')
  pdfDoc.setCreator('Envelope by Sawmill Design')
  pdfDoc.setCreationDate(new Date())

  const Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

  // Add a blank page to the document
  const page = pdfDoc.addPage([width, height])

  let lineText = []
  let yOffset, xOffset

  /* Sender Address */
  yOffset = height - 35
  xOffset = 30
  if (sender.name) {
    page.drawText(sender.name, {
      x: xOffset,
      y: yOffset,
      size: 11,
      font: HelveticaBold,
      color: rgb(0, 0, 0),
    })
    yOffset = yOffset - 12
  }

  if (sender.addr1) {
    lineText.push(`${sender.addr1}\n`)
  }
  if (sender.city) {
    lineText.push(sender.city)
  }
  if (sender.state) {
    lineText.push(` ${sender.state}`)
  }
  if (sender.postalCode) {
    lineText.push(`  ${sender.postalCode}`)
  }
  if (lineText.length > 0) {
    page.drawText(lineText.join(''), {
      x: xOffset,
      y: yOffset,
      size: 10,
      lineHeight: 11,
      font: Helvetica,
      color: rgb(0, 0, 0),
    })
  }

  /* Recipient Address */
  yOffset = height / 2
  xOffset = width / 2 - 90
  if (recipient.name) {
    page.drawText(recipient.name, {
      x: xOffset,
      y: yOffset,
      size: 13,
      font: HelveticaBold,
      color: rgb(0, 0, 0),
    })
    yOffset = yOffset - 15
  }

  lineText = []
  if (recipient.addr1) {
    lineText.push(`${recipient.addr1}\n`)
  }
  if (recipient.city) {
    lineText.push(recipient.city)
  }
  if (recipient.state) {
    lineText.push(` ${recipient.state}`)
  }
  if (recipient.postalCode) {
    lineText.push(`  ${recipient.postalCode}`)
  }
  if (lineText.length > 0) {
    page.drawText(lineText.join(''), {
      x: xOffset,
      y: yOffset,
      size: 12,
      lineHeight: 15,
      font: Helvetica,
      color: rgb(0, 0, 0),
    })
  }

  /* Generate PDF code */
  let base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true })
  //base64DataUri = base64DataUri.replace(';base64', ';name=envelope.pdf;toolbar=1;base64')
  // The above is not working to name the file--at least not in Chrome.

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfAsArray = await pdfDoc.save()
  //setPdfUint8Array(pdfAsArray)
  return {
    pdfAsArray, 
    base64DataUri,
    download: () => saveByteArray('envelope', pdfAsArray),
  }
}

export default Pdf

export {
  inchToPx,
  makeAllCaps,
}

